<script setup>
	const props = defineProps({
		title: {
			type: String,
			required: true,
		},
		tag: {
			type: String,
			required: false,
			default: 'h2',
		},
		font: {
			type: String,
			required: false,
			default: 'h1',
		},
	});

	const { title, tag, font } = toRefs(props);
</script>

<template>
	<div class="title-wrapper">
		<MessHtml v-if="title" :html="title" :tag="tag" class="text-outline" :class="font" :key="title + tag + font" />
	</div>
</template>

<style lang="scss">
	.title-wrapper {
		transition: all var(--text-reveal);
		overflow: hidden;
		.text-outline {
			transition: all var(--text-reveal);
			transform: translateY(0);
			opacity: 1;
		}
	}
</style>
